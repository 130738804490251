﻿class IncorrectEmailAddressError extends Error {
  constructor (
    message = 'Не верный формат электронной почты',
    filename,
    lineNumber
  ) {
    super(message, filename, lineNumber)
  }
}

export default IncorrectEmailAddressError
