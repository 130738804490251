﻿import IncorrectEmailAddressError from '../errors/incorrect-email-address-error'
import IncorrectCommentError from '../errors/incorrect-comment-error'
import EmptyFieldsError from '../errors/empty-fields-error'

class LeadOrderDTO {
  constructor ({ comment, emailAddress, houseId, appealType }) {
    /* eslint-disable no-useless-escape */
    this.re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if ((!comment || !comment.trim()) && !this.re.test(emailAddress)) {
      throw new EmptyFieldsError()
    }

    if (!comment || !comment.trim()) {
      throw new IncorrectCommentError()
    }

    if (!this.re.test(emailAddress)) {
      throw new IncorrectEmailAddressError()
    }

    this.emailAddress = emailAddress
    this.comment = comment
    this.houseId = houseId
    this.appealType = appealType
  }
}

export default LeadOrderDTO
