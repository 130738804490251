.pagination {
  display: flex;
  width: 100%;
  margin-top: 54px;
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 1320px) {
  .pagination {
    padding-left: 0;
    padding-right: 0;
  }
}

.pagination__page {
  background: var(--color-extralight-grey);
  border-radius: 8px;
  padding: 12px 16px;
  text-decoration: none;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--color-grey); 
  border: 2px solid transparent;
  min-width: 45px;
}

.pagination__page:not(:last-child) {
  margin-right: 10px;
}

.pagination__page--active {
  color: var(--color-orange-basic); 
  border: 2px solid var(--color-orange-basic);
}