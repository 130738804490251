﻿class IncorrectCommentError extends Error {
  constructor (
    message = 'Текст сообщения слишком короткий',
    filename,
    lineNumber
  ) {
    super(message, filename, lineNumber)
  }
}

export default IncorrectCommentError
