.typographer table {
  width: 100%;
  border: none;
  border-spacing: 0;
}

.typographer thead > tr:last-child > * {
  border-bottom: 1px solid var(--color-mid-grey);
}

.typographer tbody > tr:not(:last-child) > * {
  border-bottom: 1px solid var(--color-grey);
}

.typographer th {
  display: table-cell;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  color: var(--color-mid-grey);
}

.typographer thead th {
  color: var(--color-grey);
}

.typographer td {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-black);
}
