.news-card {
  background: var(--color-white);
  padding: 40px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

@media screen and (min-width: 1320px) {
  .news-card {
    height: 300px;
    width: 383px;
    padding: 25px;
  }
  .news-card:not(:last-child) {
    margin-right: 13px;
  }
}

.news-card:not(:last-child) {
  margin-bottom: 10px;
}

@media screen and (min-width: 1320px) {
  .news-card:not(:last-child) {
    margin-bottom: 13px;
  }
}

.news-card__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: var(--color-teal-base);
}

.news-card__text {
  line-height: 20px;
  color: var(--color-mid-grey);
  padding-top: 12px;
}

.news-card__link:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.news-card:hover .news-card__title {
  text-decoration: underline;
}