﻿const ATTR_NAME = 'data-dispatch-widget'

function init () {
  const widgetOpenButtons = document.querySelectorAll(
    `[${ATTR_NAME}]`
  )

  for (const button of widgetOpenButtons) {
    const {
      dataset: {
        houseId,
        houseAddressString,
        dispatchWidget: service
      }
    } = button

    button.addEventListener('click', () => {
      window.rkDispatchWidget
        .prefillAddress(houseId, houseAddressString)
        .setService(service)
        .setCurrentStep('AddressStep')
        .open()
    })
  }
}

export default init
