﻿import Cookie from '../modules/cookies'

const notify = document.querySelector(
  '.cookie-policy-notify'
)

const closeButton = notify.querySelector(
  '.cookie-policy-notify__button'
)

function init () {
  if (Cookie.getCookie('.UseCookieAgree')) {
    return
  }

  notify.classList.remove('hidden')
  closeButton.addEventListener('click', onCloseButtonClick)
}

function onCloseButtonClick () {
  notify.classList.add('hidden')
  Cookie.setCookie('.UseCookieAgree', 1, 365)
  document.removeEventListener('click', onCloseButtonClick)
}

export default init
