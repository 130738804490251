.buttons-group {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
}

.buttons-group--type--row {
flex-direction: row;
}

@media (max-width: 1320px) {
  .buttons-group > *:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .buttons-group > *:not(:last-child) {
    border-bottom-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media (min-width: 1320px) {
  .buttons-group {
    flex-direction: row;
  }

  .buttons-group > *:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  .buttons-group > *:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.buttons-group__item--group-type--row {
  border-radius: 0;
}

.buttons-group__item--group-type--row:not(:first-child) {
  border-left: none;
}

.buttons-group__item--group-type--row:first-child {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}

.buttons-group__item--group-type--row:last-child {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
}