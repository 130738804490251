.card {
  border-radius: 6px;
  overflow: hidden;
  --padding-x: 10px;
}

@media (min-width: 1320px) {
  .card {
    --padding-x: 48px;
  }
}

.card__header {
  background-color: var(--color-extralight-grey);
}

@media (min-width: 1320px) {
  .card__header {
    padding: 32px var(--padding-x) 16px;
  }
}

.card__content {
  background-color: var(--color-white);
  padding: 28px var(--padding-x);
}

@media (min-width: 1320px) {
  .card__content {
    padding: 36px var(--padding-x);
  }
}
