.house-page-tabs {
  display: flex;
  align-items: center;
  overflow-x: auto;
  list-style: none;
  margin: 0;
  padding: 20px var(--padding-x);
}

.house-page-tabs > * {
  margin-left: 0;
  margin-right: 0;
}

.house-page-tabs > * + * {
  margin-left: 22px;
}

@media (min-width: 1320px) {
  .house-page-tabs > * + * {
    margin-left: 50px;
  }
}

.house-page-tabs__tab {
  white-space: nowrap;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  text-decoration: none;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: var(--color-orange-basic);
}

.house-page-tabs__tab--active {
  color: var(--color-black);
  pointer-events: none;
}

@media (min-width: 1320px) {
  .house-page-tabs__tab {
    font-size: 15px;
    line-height: 20px;
  }
}
