﻿// core version + navigation + autoplay, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper'
// import Swiper styles
import 'swiper/swiper-bundle.css'

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay])

function initSwiper () {
  /* eslint-disable no-new */
  new Swiper('.header-slider', {
    // Optional parameters
    loop: false,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  })
}

export default initSwiper
