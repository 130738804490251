﻿import { Modal } from '@roskvartal-landings/component-modal'

class EmailConfirmModal extends Modal {
  constructor (el) {
    super(el)
    this.init()
  }

  init () {
    this.refs.get('cancel-btn').addEventListener('click', () => {
      this.close()
    })
  }
}

export default EmailConfirmModal
