﻿import router from './utils/router'
import './main.css'
import { App } from '@roskvartal-landings/base'
import { ModalPlugin } from '@roskvartal-landings/plugin-modal'
import initNavbar from './components/navbar'
import initLightbox from './components/lightbox'
import EmailModal from './components/email-modal'
import EmailConfirmModal from './components/email-confirm-modal'
import initWidget from './utils/dispatch-widget'
import initSwiper from './plugins/swiper'
import initCookiePolicyNotify from './plugins/cookie-policy-notify'
import initPreloader from './components/preloader'

/**
 * ['имя-пути': import('ресурс')]
 *
 * Если `имя-пути` соответствует атрибуту `[data-route]`
 * на странице, будет загружен соответствующий `русурс` (js-файл).
 */

router({
  home: import('./pages/home'),
  houses: import('./pages/houses'),
  news: import('./pages/news'),
  newsArticle: import('./pages/news-article'),
  aboutCompany: import('./pages/about-company'),
  house: import('./pages/house'),
  housePhotos: import('./pages/house-photos'),
  houseDocuments: import('./pages/house-documents'),
  houseAppeals: import('./pages/house-appeals'),
  houseNews: import('./pages/house-news'),
  contacts: import('./pages/contacts'),
  page: import('./pages/page'),
  placeholder: import('./pages/placeholder')
})

const app = new App()

app.usePlugin(ModalPlugin)
app.registerComponent('email-modal', EmailModal)
app.registerComponent('email-confirm-modal', EmailConfirmModal)
app.init()

initNavbar()
initWidget()
initLightbox()
initSwiper()
initCookiePolicyNotify()
initPreloader()
