import { Modal } from '@roskvartal-landings/component-modal'
import LeadOrderDTO from '../dto/lead-modal'
import IncorrectEmailAddressError from '../errors/incorrect-email-address-error'
import IncorrectCommentError from '../errors/incorrect-comment-error'
import EmptyFieldsError from '../errors/empty-fields-error'

class EmailModal extends Modal {
  constructor (el) {
    super(el)

    this.data = {
      comment: null,
      emailAddress: null
    }

    this.init()
  }

  init () {
    const emailAddress = this.refs.get('email-address')
    const comment = this.refs.get('comment')
    const form = this.refs.get('form')
    const cancelBtn = this.refs.get('cancel-btn')
    const errorEmail = this.refs.get('error-email')
    const errorText = this.refs.get('error-text')

    this.data.emailAddress = emailAddress.querySelector('input').value
    this.data.comment = comment.querySelector('textarea').value

    this.el.addEventListener('modal-open', (e) => {
      if (e.detail) {
        this.data.houseId = e.detail.houseId
        this.data.appealType = e.detail.appealType
      }
    })

    emailAddress.addEventListener('input', (e) => {
      this.data.emailAddress = e.target.value

      emailAddress.querySelector('input').classList.remove('is-invalid')
      errorEmail.classList.remove('active-text')
    })

    comment.addEventListener('input', (e) => {
      this.data.comment = e.target.value

      comment.querySelector('textarea').classList.remove('is-invalid')
      errorText.classList.remove('active-text')
    })

    form.addEventListener('submit', (e) => {
      e.preventDefault()
      this.sendEmail()
    })

    cancelBtn.addEventListener('click', () => {
      this.close()
    })
  }

  sendEmail () {
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    this.refs.get('send-button').disabled = true

    let payload
    const confirmModal = document.getElementById('emailConfirmModal')

    try {
      payload = new LeadOrderDTO(this.data)
    } catch (e) {
      this.refs.get('send-button').disabled = false
      this.updateInvalidState(e)
      return
    }

    fetch('/contacts/sendContactUsEmail', {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(() => this.close()).then(() => confirmModal.dispatchEvent(Modal.openEvent)).catch((error) => {
        console.log(error)
      })

    sleep(2000)
      .then(() => {
        this.refs.get('send-button').disabled = false
      })
  }

  updateInvalidState (error) {
    switch (error.constructor) {
      case IncorrectEmailAddressError:
        this.refs
          .get('email-address')
          .querySelector('input')
          .classList.add('is-invalid')
        this.refs
          .get('error-email')
          .classList.add('active-text')
        break
      case IncorrectCommentError:
        this.refs
          .get('comment')
          .querySelector('textarea')
          .classList.add('is-invalid')
        this.refs
          .get('error-text')
          .classList.add('active-text')
        break
      case EmptyFieldsError:
        this.refs
          .get('email-address')
          .querySelector('input')
          .classList.add('is-invalid')
        this.refs
          .get('error-email')
          .classList.add('active-text')
        this.refs
          .get('comment')
          .querySelector('textarea')
          .classList.add('is-invalid')
        this.refs
          .get('error-text')
          .classList.add('active-text')
        break
    }
  }
}

export default EmailModal
