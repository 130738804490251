﻿class EmptyFieldsError extends Error {
  constructor (
    message = 'Не верный формат электронной почты и Текст сообщения слишком короткий',
    filename,
    lineNumber
  ) {
    super(message, filename, lineNumber)
  }
}

export default EmptyFieldsError
