﻿async function renderPage (pages) {
  const name = getPageName()

  if (!name) {
    return
  }

  const page = pages[name]

  if (!page) {
    throw new Error(`Страница с именем "${name}" не найдена.`)
  }

  await page
}

function getPageName () {
  const root = document.querySelector('[data-route]')
  if (!root) { return }
  return root.dataset.route
}

export default renderPage
