.house-page-header {
  display: block;
}

@media (min-width: 1320px) {
  .house-page-header {
    background-color: var(--color-white);
  }
}

.house-page-header__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 24px;
  padding-bottom: 24px;
}

.house-page-header__content > * {
  margin-top: 0;
  margin-bottom: 0;
}

.house-page-header__content > * + * {
  margin-top: 20px;
}

@media (min-width: 1320px) {
  .house-page-header__content {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 42px;
    padding-bottom: 42px;
  }

  .house-page-header__content > * {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .house-page-header__content > * + * {
    margin-top: 20px;
  } 
}

.house-page-header__title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 26px;
  line-height: 29px;
}

@media (min-width: 1320px) {
  .house-page-header__title {
    font-size: 36px;
    line-height: 39px;
    /* or 108% */
    letter-spacing: -0.01em;
    max-width: 60%;
  }
}
