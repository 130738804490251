﻿import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.min.css'

const LIGHTBOX_CLASS = '.image-gallery'
const TOOLBAR_CONFIG = {
  toolbar: {
    prev: 2,
    zoomOut: 2,
    oneToOne: 2,
    zoomIn: 2,
    next: 2,
    play: {
      show: false
    },
    reset: false,
    rotateLeft: false,
    rotateRight: false,
    flipHorizontal: false,
    flipVertical: false
  },
  url (images) {
    return images.getAttribute('data-src')
  }
}

export default function init () {
  const images = document.querySelector(LIGHTBOX_CLASS)
  if (!images) {
    return
  }
  return new Viewer(images, TOOLBAR_CONFIG)
}
